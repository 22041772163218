import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

// Images
import bg1 from './assets/images/background-half-x.png'
import bg2 from './assets/images/background-x.png'

export const Wrapper = styled.div`
   h2, h3, h4, h5 {
    font-family: "Sora", Helvetica, sans-serif;
  }
`
export const VideoSection = styled.section`
  min-height: 100vh;
  background-color: ${grayscale[500]};
  background-image: url(${bg1}), url(${bg2});
  background-repeat: no-repeat, no-repeat;
  background-position: 24px calc(100% - 120px), calc(100% - 24px) calc(100% - 120px);

  @media ${device.desktopLG} {
    padding-top: 112px;
    min-height: 100vh;
  }

  .video-container {
    position: relative;
  }

  .open-video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;

    .video-cursor {
      cursor: pointer;
    }
  }
`

export const InvestSection = styled.section`
  display: flex;
  align-items: center;
  min-height: 300px;
  background-color: ${orange.extra};

  h2 {
    font-weight: 200 !important;
  }
`

export const DotsDiv = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  cursor: pointer;

  div {
    max-width: 150px;
    width: 100%;
  }

  .default-bg {
    min-height: 80px;
    background-color: ${grayscale[400]};
  }
`
